<template>
  <div class="columns">
    <div class="column is-one-third">
      <card-component
        title="1. Select the dataset to analyze"
        class="tile is-child"
        :displayHelp="$options.name"
      >
        <validation-observer v-slot="{ handleSubmit }">
          <validation-provider
            v-slot="{ errors }"
            :rules= {required:true}
            name="datasetName"
          >
            <dataset-select
              label="Select a dataset"
              :dataset.sync="datasetName"
              v-model="datasetName"
              :withVarname="false"
              :type="{ 'is-danger': errors[0] }"
              :message="errors"
              icon="table"
              pack="fa"
              expanded
            />
          </validation-provider>
          <hr />
          <b-field label="Interactive graph">
            <b-switch v-model="interactive">
              Compute interactive graph
              <font size="2"><p style="color:red;">Warning! For large datasets, it can be very long!</p></font>
            </b-switch>
          </b-field>
          <hr />
          <div class="has-text-centered">
            <b-button rounded
              class="is-primary button-shadow"
              @click.prevent="handleSubmit(run)"
            >
              <span class="icon is-small"><i class="fa fa-cogs"></i></span>
              <span> Run </span>
            </b-button>
          </div>
        </validation-observer>
      </card-component>
    </div>
    <div class="column">
      <card-component title="2. Plots and numerical summaries" />
      <error :type='"notifications"' ref="notifications"></error>
      <card-no-result v-show="!resultsLoaded"/>
      <div v-if="resultsLoaded">
        <div v-if="hasResults">
          <card-component>
            <b-tabs @input="displayResult">
              <b-tab-item label="Heatmaps" icon="bar-chart" value="heatmap" />
              <b-tab-item label="Plots" icon="bar-chart" value="plots" />
              <b-tab-item v-if="basicTables" label="Summary" icon="table" value="basic-table" />
            </b-tabs>
          </card-component>
          <card-component v-if="currentResult==='heatmap'">
            <png
              v-if="originalHeatmap.type==='png'"
              :data="originalHeatmap"
            />
            <plotly
              v-else
              :data="originalHeatmap"
            />
            <png
              v-if="orderedHeatmap.type==='png'"
              :data="orderedHeatmap"
            />
            <plotly
              v-else
              :data="orderedHeatmap"
            />
          </card-component>

          <card-component v-if="currentResult==='plots'">
            <div v-if=png>
              <png :data="png"/>
              <plotly :height="800"
                :data="graphical"
              ></plotly>
            </div>
            <plotly-list v-else
              :data="graphicals"
            ></plotly-list>

          </card-component>

          <div v-else-if="currentResult==='basic-table'">
            <card-component
              v-for="(bTable,index) in basicTables"
              :key="index"
              :title="bTable.title"
            >
              <basic-table
                :substrRowname="false"
                :data="bTable"
              />
            </card-component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { ranalysis } from '@/mixins/ranalysis'

export default {
  name: 'RMissingOverview',
  props: {
    datasetP: {
      type: String,
      default: null
    }
  },
  components: {
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider,
    'dataset-select': () => import('@/components/ui/DatasetSelect.vue'),
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'card-no-result': () => import('@/components/ui/CardNoResult.vue'),
    'basic-table': () => import('@/components/rresults/BasicTable.vue'),
    'plotly': () => import('@/components/rresults/Plotly.vue'),
    'png': () => import('@/components/rresults/PNG.vue'),
    'plotly-list': () => import('@/components/ui/PlotlyList.vue'),
    'error': () => import('@/components/ui/Error.vue')
  },
  mixins: [ranalysis],
  data () {
    return {
      datasetName: this.datasetP,
      originalHeatmap: null,
      orderedHeatmap: null,
      interactive: false,
      currentResult: 'heatmap'
    }
  },
  computed: {
    excludeResults: function () {
      return ['OriginalHeatmap', 'OrderedHeatmap']
    },
    params: function () {
      return {
        'func_name': 'r_missing_overview',
        'datasetName': this.datasetName,
        'out_graph': true,
        'interactive': this.interactive
      }
    }
  },
  methods: {
    beforeRun: function (data) {
      this.originalHeatmap = data.OriginalHeatmap
      this.orderedHeatmap = data.OrderedHeatmap
    },
    displayResult: function (value) {
      this.currentResult = value
    },
    afterRun: function () {
      this.$emit('is-computed')
    }
  }
}
</script>

<style>
  .b-tabs .tab-content {
    padding: 0rem;
  }
  .tabs li.is-active a {
    border-bottom-color: #1896E1 !important;
    color: #1896E1 !important;
  }
</style>
